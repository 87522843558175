import { useFetchCampaignBudgetQuery } from "../../store";

export default function BudgetUsage({ accountId, campaignId }) {
    const { data, isSuccess, isFetching } = useFetchCampaignBudgetQuery({ accountId, campaignId });
    let budgetUsage = -1
    let className = ""
    let outOfBudget
    if (isFetching) {

    }
    else if (data && isSuccess && data.success) {
        //console.log(data)
        budgetUsage = data.success[0].budgetUsagePercent
        if (budgetUsage > 97) {
            className = " bg-red-600 text-white"
            outOfBudget = 1
        }
        else if (budgetUsage > 80) {
            className = " bg-orange-500 text-white"
            outOfBudget = 2
        }
        else if (budgetUsage > 60) {
            className = " bg-yellow-100 text-gray-900"
        }
    }

    return <div className="justify-items-center mb-3">
        {outOfBudget === 1 ? <div className="text-center bg-red-600 text-white text-xxs px-1 my-1 rounded-md">Out of Budget</div> :
            outOfBudget === 2 ? <div className="text-center bg-orange-500 text-white text-3xs px-1 my-1 rounded-md">Almost Out of Budget</div> : ""}
        <div className={"text-center text-xxs w-fit px-1 text-gray-400 rounded-md" + className}>% {budgetUsage.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
    </div>
}