import { useState } from "react"
import Modal from "../Modal"
import NumberGuess from "./NumberGuess"

export default function GamesHomePage() {
    const [showModal, setShowModal] = useState(false)

    const onClick = () => {
        console.log("Number Guess clicked")
        setShowModal(true)
    }

    function onChange() {
        setShowModal(false);
    }

    const modal = <Modal onChange={onChange}>
        <NumberGuess></NumberGuess>
    </Modal>
    return <div>
        {showModal && modal}
        <div className="shadow border rounded-xl p-2 m-3 text-center font-bold">Games Home Page</div>
        <div className="p-3">
            <div className="flex w-56 h-56 shadow rounded-xl border items-center hover:cursor-pointer" onClick={onClick}>
                <div className="w-full text-center">Number Guess</div>
            </div>
        </div>
    </div>
}