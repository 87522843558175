import { useSelector } from "react-redux";
import { useState } from "react";
import { GoSync } from "react-icons/go";
import { FcOk, FcHighPriority } from "react-icons/fc";
import "./pauseToggle.css"
import { useUpdateStateMutation } from "../../store";

function ToggleSwitch({ isChecked, keywordId, type }) {
    const accountId = useSelector(state => state.accounts.accountId);
    let defaultChecked = false
    if (isChecked === "ENABLED") defaultChecked = true
    const [updateState, res] = useUpdateStateMutation()
    const [disabled, setDisabled] = useState(false)
    const [checked, setChecked] = useState(defaultChecked)

    const onChange = async (event) => {
        setDisabled(true)
        setChecked(event.target.checked)
        let keywordState = ""
        console.log(event.target.checked)
        console.log(keywordId)
        console.log(accountId)
        if (event.target.checked) {
            keywordState = "ENABLED"
        }
        else {
            keywordState = "PAUSED"
        }
        await updateState({ keywordState, keywordId, accountId, type })
        setDisabled(false)
    }

    return (
        <div>
            {
                res.isUninitialized
                    //ilk başlangıç
                    ? <label className="switch">
                        <input type="checkbox" onChange={onChange} defaultChecked={defaultChecked} disabled={disabled}></input>
                        <span className="slider1 round1 switch1"></span>
                    </label>
                    : res.isLoading
                        // yükleniyor
                        ? <GoSync className="animate-spin" />
                        : res.isSuccess && res.data.success.length > 0
                            // başarılı oldu
                            ? <div className="flex flex-row">
                                <label className="switch">
                                    <input type="checkbox" onChange={onChange} defaultChecked={checked} disabled={disabled}></input>
                                    <span className="slider1 round1 switch1"></span>
                                </label>
                                <div className="text-xxs">
                                    <FcOk></FcOk>
                                </div>
                            </div>
                            //başarısız oldu
                            : <div className="flex flex-row">
                                <label className="switch">
                                    <input type="checkbox" onChange={onChange} defaultChecked={!checked} disabled={disabled}></input>
                                    <span className="slider1 round1 switch1"></span>
                                </label>
                                <div className="text-xxs">
                                    <FcHighPriority></FcHighPriority>
                                </div>
                            </div>

            }
        </div>


    );
}

export default ToggleSwitch;