import { updateAccount, updateCampaignId, updatePortfolioId } from "./../store";
import { useDispatch } from "react-redux";
import AccountDropdown from "./AccountDropdown"

function SelectAccountDiv() {
    const dispatch = useDispatch();

    const setAccountId = (account) => {
        dispatch(updateAccount(account));
    };
    const updateCampaign = (account) => {
        dispatch(updateCampaignId(account));
    };
    const updatePortfolio = (account) => {
        dispatch(updatePortfolioId(account));
    };


    return (
        <div>
            <div className="m-5 p-2 rounded-xl shadow">Select an account to display the page</div>
            <div className="m-5">
                <AccountDropdown
                    setAccountId={setAccountId}
                    updateCampaign={updateCampaign}
                    updatePortfolio={updatePortfolio}></AccountDropdown>
            </div>
        </div>
    )
}
export default SelectAccountDiv