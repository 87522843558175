import { useSelector } from "react-redux"
import { useState } from 'react'
import SalesTrafficList from "./SalesTrafficList"
import SalesTrafficListParent from "./SalesTrafficListParent"
import SelectAccountDiv from "../SelectAccountDiv"
import Filter from "./Filters"

function SalesTrafficPage() {
    const accountId = useSelector(state => state.accounts.accountId);
    const [isWeekly, setIsWeekly] = useState(true)
    const [view, setView] = useState("std")


    return <div className="my-3">
        <div className="flex justify-center mx-3 py-2 border rounded-xl font-bold shadow">Sales and Traffic Page</div>
        {accountId
            ? <div>

                <Filter isWeekly={isWeekly} setIsWeekly={setIsWeekly} view={view} setView={setView}></Filter>
                <div>
                    <div className="my-1 overflow-scroll"><SalesTrafficList accountId={accountId} isWeekly={isWeekly}></SalesTrafficList></div>
                    <div className="my-1 overflow-scroll"><SalesTrafficListParent accountId={accountId} isWeekly={isWeekly}></SalesTrafficListParent></div>
                </div>
            </div>
            : <SelectAccountDiv></SelectAccountDiv>
        }

    </div>
}

export default SalesTrafficPage