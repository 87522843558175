import PataraBid from "./PataraBid";
import AcosDisplay from "./AcosDisplayDiv";

export const GROUPED_COLUMNS = [
    {
        Header: '',
        Footer: '',
        id: 'patara',
        columns: [
            {
                Header: 'Keyword',
                Footer: 'Keyword',
                accessor: 'targeting',
                Cell: ({ row, value }) => {
                    let text = value;
                    let category, brand, rating, prime;
                    const lastUpdate = row.original.lastUpdate
                    const matchType = row.original.matchType
                    let updatedDate = "Never", updatedTime = "", updatedFrom = "", updatedTo = "", updatedBy = ""
                    if (lastUpdate) {
                        const date = new Date(lastUpdate.updatedDate)
                        updatedDate = date.toISOString().split('T')[0]
                        updatedTime = date.toISOString().slice(11, 16)
                        updatedFrom = lastUpdate.updatedFrom
                        updatedTo = lastUpdate.updatedTo
                        updatedBy = lastUpdate.updatedBy
                    }
                    if (value.includes('category="')) {
                        const index = value.indexOf('"', 10);
                        text = value.slice(10, index);
                        category = value.slice(10, index);
                        if (value.includes('brand="')) {
                            const indexBrStart = value.indexOf('brand="') + 7;
                            const indexBrEnd = value.indexOf('"', indexBrStart);
                            brand = value.slice(indexBrStart, indexBrEnd);
                        }
                        if (value.includes('rating=')) {
                            const indexRating = value.indexOf('rating=') + 7;
                            rating = value.slice(indexRating);
                        }
                        if (value.includes('prime-shipping-eligible="')) {
                            const indexPrime = value.indexOf('prime-shipping-eligible="') + 25;
                            prime = value.slice(indexPrime, indexPrime + 5);
                        }
                    }

                    let keywordIdDiv = <div className="mt-1 text-3xs text-gray-300">{row.original.keywordId}</div>
                    let matchTypeDiv = <div className="mb-1 text-4xs text-gray-400">{row.original.matchType}</div>

                    let targetingDiv
                    if (value.includes('asin="')) {
                        targetingDiv = <div>
                            <a href={"https://www.amazon.com/dp/" + value.slice(6, 16)} rel='noreferrer' target="_blank">
                                <div className='flex hover:text-sky-600 hover:cursor-pointer'>{value.slice(6, 16)}</div>
                            </a>
                        </div>
                    } else if (value.includes('asin-expanded="')) {
                        targetingDiv = <div>
                            <a href={"https://www.amazon.com/dp/" + value.slice(15, 25)} rel='noreferrer' target="_blank">
                                <div className='flex hover:text-sky-600 hover:cursor-pointer'>{value.slice(15, 25)}</div>
                            </a>
                        </div>
                    } else if (value.includes('category="')) {
                        targetingDiv = <div>
                            <a href={"https://www.amazon.com/s?k=" + category} rel='noreferrer' target="_blank">
                                <div className='flex hover:text-sky-600 hover:cursor-pointer'>{text}</div>
                            </a>
                            <div className="flex flex-row text-xxs mt-1">
                                {brand && <div className="mr-3">Brand: {brand}</div>}
                                {rating && <div>Rating: {rating}</div>}
                                {prime && <div>Prime: {prime}</div>}
                            </div>
                        </div>
                    } else if (matchType === 'EXACT' || matchType === 'PHRASE' || matchType === 'BROAD') {
                        targetingDiv = <div>
                            <div className='flex items-center'>
                                <a href={"https://www.amazon.com/s?k=" + value} rel='noreferrer' target="_blank" className='flex hover:text-sky-600'>
                                    {value}
                                </a>
                                <div className="bg-sky-500 text-white rounded-full w-4 h-4 flex justify-center text-xxs ml-2 lowercase font-bold">
                                    {row.original.matchType.slice(0, 1)}
                                </div>
                            </div>
                        </div>
                    } else {
                        targetingDiv = <div>
                            <div className='flex'>{value}</div>
                        </div>
                    }

                    return (
                        <div className="pl-1 break-words">
                            <div className="bg-sky-100 px-2 py-1 rounded">
                                {targetingDiv}
                            </div>
                            {keywordIdDiv}
                            {matchTypeDiv}
                            {
                                lastUpdate
                                    ? <div className="flex flex-col text-xxs text-gray-700 bg-gray-100 rounded-lg px-1 py-1 w-48">
                                        <div>{updatedDate} - {updatedTime}</div>
                                        <div className="flex flex-row">
                                            <div className="font-bold">{updatedFrom + " >>> " + updatedTo}</div>
                                            <div className="ml-2 text-gray-400">{updatedBy}</div>
                                        </div>
                                        <div></div></div>
                                    : <div className="text-xxs text-gray-700 bg-gray-100 rounded-lg px-1 py-1">Last Update: Never</div>
                            }
                        </div>
                    )
                }
            },
            /*
            {
                Header: 'Bid',
                Footer: 'Bid',
                accessor: 'keywordBid',
                Cell: ({ value }) => <div className='flex justify-center px-2'>{value === null ? '-' : value.toFixed(2)}</div>
            },
            */
            {
                Header: 'Patara Bid',
                Footer: 'Patara Bid',
                id: 'pataraBid',
                Cell: ({ row }) => <PataraBid id={row.original.keywordId} row={row.original} currBid={row.original.bid}></PataraBid>
            },
        ]
    },
    {
        Header: 'Week 1',
        Footer: 'Week 1',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_1',
                sortDescFirst: true,
                Cell: ({ value, row }) => {
                    const spend = row.original.cost_1
                    return <AcosDisplay value={value} spend={spend}></AcosDisplay>
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'cost_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end font-bold'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
    {
        Header: 'Week 2-5',
        Footer: 'Week 2-5',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_4',
                sortDescFirst: true,
                Cell: ({ value, row }) => {
                    const spend = row.original.cost_4
                    return <AcosDisplay value={value} spend={spend}></AcosDisplay>
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'cost_4',
                id: "spend_4",
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end font-bold'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
    {
        Header: 'Week 6-9',
        Footer: 'Week 6-9',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_8',
                sortDescFirst: true,
                Cell: ({ value, row }) => {
                    const spend = row.original.cost_8
                    return <AcosDisplay value={value} spend={spend}></AcosDisplay>
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'cost_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end font-bold'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
    {
        Header: 'Week 10-13',
        Footer: 'Week 10-13',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_13',
                sortDescFirst: true,
                Cell: ({ value, row }) => {
                    const spend = row.original.cost_13
                    return <AcosDisplay value={value} spend={spend}></AcosDisplay>
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'cost_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end font-bold'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
]