import { useState, useEffect } from "react";
import { FaEdit, FaCheckCircle, FaTimesCircle } from "react-icons/fa"
import { useUpdateParentsSkuMutation } from "../../store";
import EnableToggle from "./EnableToggle"

function EditForm({ accountId, parent }) {
    const [editForm, setEditForm] = useState(false)
    const [class1, setClass1] = useState("font-bold mr-3 p-2 rounded-md bg-sky-700 text-white border w-52")
    const [formValue, setFormValue] = useState(parent.customParentName || parent.skus[0].seller_sku)
    const [formValue2, setFormValue2] = useState(parent.priority || 4)
    const [formValue3, setFormValue3] = useState(parent.portfolioName || parent.customParentName)
    const [formValueReset, setFormValueReset] = useState(parent.customParentName || parent.skus[0].seller_sku)
    const [updateSku, response] = useUpdateParentsSkuMutation()

    useEffect(() => {
        if (response.isSuccess) {
            setClass1("font-bold mr-3 p-2 rounded-md bg-green-700 text-white border w-52")
        }
        if (response.isError) {
            setClass1("font-bold mr-3 p-2 rounded-md bg-red-700 text-white border w-52")
        }
    }, [response])

    const onEditClick = () => {
        setFormValueReset(formValue)
        setEditForm(true)
    }
    const onConfirmClick = async () => {
        await updateSku({ accountId, asin: parent.asin, sku: formValue, priority: formValue2, portfolio: formValue3 })
        //console.log("Form value and database info are the same!!")
        setEditForm(false)
    }
    const onCancelClick = () => {
        setFormValue(formValueReset)
        setEditForm(false)
    }
    const onChange = (event) => {
        const value = event.target.value;
        setFormValue(value);
    }
    const onChange2 = (event) => {
        const value = event.target.value;
        if ((event.target.value < 6 && event.target.value > 0) || event.target.value === "")
            setFormValue2(value);
    }
    const onChange3 = (event) => {
        const value = event.target.value
        setFormValue3(value)
    }
    return (
        <div className="flex flex-row justify-items-center">
            <div>
                {
                    editForm
                        ? <div className="flex flex-row items-center my-2">
                            <div className="flex flex-col">
                                <input type='text' value={formValue} onChange={onChange} className="font-bold mr-3 p-2 rounded-md border w-52"></input>
                                <div className="flex flex-row my-1">
                                    <input type='number' min={1} max={5} value={formValue2} onChange={onChange2} className="font-bold mr-3 p-1 rounded-md border w-10 text-xxs"></input>
                                    <input type='text' value={formValue3} onChange={onChange3} className="font-bold mr-3 p-1 rounded-md border w-32 text-xxs"></input>
                                </div>
                            </div>
                            <div className="text-sm px-1 hover:cursor-pointer" onClick={onConfirmClick}><FaCheckCircle /></div>
                            <div className="text-sm px-1 hover:cursor-pointer" onClick={onCancelClick}><FaTimesCircle /></div>
                        </div >
                        : <div className="flex flex-row items-center my-2">
                            <div className="flex flex-col">
                                <div className={class1}>{formValue}</div>
                                <div className="flex flex-row my-1">
                                    <div className="font-bold mr-3 p-1 rounded-md border w-10 text-xxs">{formValue2}</div>
                                    <div className="font-bold mr-3 p-1 rounded-md border w-32 text-xxs">{formValue3}</div>
                                </div>
                            </div>
                            <div className="text-lg px-1 hover:cursor-pointer" onClick={onEditClick}><FaEdit /></div>
                        </div>
                }
            </div>
            <div className="flex mx-3 items-center">
                <EnableToggle isChecked={parent.isDisplayed} asin={parent.asin}></EnableToggle>
            </div>
        </div>
    )
}
export default EditForm