import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GoSync } from "react-icons/go";
import { MdBackup, MdOutlineUpdate } from "react-icons/md";
import { FcBearish, FcBullish, FcMinus, FcOk, FcHighPriority } from "react-icons/fc";
import { useUpdateBidMutation, useGetPataraBidQuery, updatePataraBids } from "../../store";

export default function PataraBid({ id, currBid, row }) {
    const dispatch = useDispatch();

    // ADD NEW STATE - FOR 30 DAYS - HANGİ TIME FRAME AĞIRLIKLI OLACAK - SEÇENEK DROPDOWN VEYA BUTON
    const targetAcos = useSelector(state => state.accounts.targetAcos);
    const avgPrice = useSelector(state => state.accounts.avgPrice);
    const accountId = useSelector(state => state.accounts.accountId);
    const campaignId = useSelector(state => state.accounts.campaignId)
    const pataraBid = useSelector(state => state.patarabids.pataraBids.find(targeting => targeting.id === id));
    const [formValue, setFormValue] = useState('');
    const [rules, setRules] = useState('');
    const [bids, setBids] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [filterInvisible, setFilterInvisible] = useState(true);
    const { data } = useGetPataraBidQuery({ row, targetAcos, avgPrice });
    const [updateBid, res] = useUpdateBidMutation();

    const classname = 'flex justify-center focus:outline-0 bg-white w-14 px-1 py-1 border border-gray-300 rounded-lg'
    const [inputClass, setInputClass] = useState(classname);
    const classname2 = 'flex justify-center items-center rounded-lg'
    const [divClass, setDivClass] = useState(classname2);

    // SB ve SD targetingleri için keyword terimi yok. Onun yerine targetingText var. Burada ayrımı
    //keyword yerine match type veya targeting type ile yapmak daha mantıklı olabilir
    let keyword
    if (row.keyword) {
        keyword = row.matchType;
    } else {
        keyword = row.targetingType
    }
    //console.log("keyword: ", keyword)

    useEffect(() => {
        if (data) {
            const bid = data.pataraBid;
            setFormValue(bid);
            dispatch(updatePataraBids([{ bid, id, keyword, currBid }]));
            const rule1 = data.adjBid1Rule;
            const rule2 = data.adjBid4Rule;
            const rule3 = data.adjBid8Rule;
            const rule4 = data.adjBid13Rule;
            setRules("" + rule1 + " - " + rule2 + " - " + rule3 + " - " + rule4)
            let bid1 = "-", bid4 = "-", bid8 = "-", bid13 = "-"
            if (data.adjBid1) bid1 = data.adjBid1.toFixed(2)
            if (data.adjBid4) bid4 = data.adjBid4.toFixed(2);
            if (data.adjBid8) bid8 = data.adjBid8.toFixed(2);
            if (data.adjBid13) bid13 = data.adjBid13.toFixed(2);
            setBids("" + bid1 + " - " + bid4 + " - " + bid8 + " - " + bid13)
        }
    }, [data, dispatch, id, keyword, currBid]);

    useEffect(() => {
        if (pataraBid && data) {
            setFormValue(pataraBid.bid);
            if (pataraBid.isSubmitted) {
                setDivClass(classname2 + ' bg-green-200')
            }
            if (pataraBid.bid !== data.pataraBid) {
                setFilterInvisible(false);
            }
        }
    }, [pataraBid, data]);

    //FORM KIRMIZI İKEN SELECT ALL DİYEMESİN YA DA
    //SUBMIT ALL DERSE HATA VERSIN
    //BU ÇOK ÖNEMLİİİİİİİ

    const onChange = (event) => {
        const value = event.target.value;
        if (data.pataraBid) {
            setFilterInvisible(false);
        }
        if (!value || value === '0' || value.slice(value.length - 1) === '.' || value < 0.1) {
            setDisabled(true);
            setInputClass((classname + ' border-red-500'));
        } else if (!isNaN(value) && ((value.length < 6 && value.slice(2, 3) === '.') || (value.length < 5 && value.slice(1, 2) === '.') || (value.length < 3))) {
            setDisabled(false);
            setInputClass((classname + ' border-green-600'));
            dispatch(updatePataraBids([{ bid: value, id, keyword, currBid, isSubmitted: false }]));
        }
        if (!isNaN(value) && ((value.length < 6 && value.slice(2, 3) === '.') || (value.length < 5 && value.slice(1, 2) === '.') || (value.length < 3))) {
            setFormValue(value);
        }
    };

    const onClick = async () => {
        //submit new bid to amazon 
        setInputClass(classname);
        if (formValue) {
            await updateBid({ selectedKeywords: [{ id, bid: formValue, keyword, currBid, updateType: "s" }], accountId, campaignId });
        }
        dispatch(updatePataraBids([{ bid: formValue, id, keyword, currBid, isSubmitted: true }]));
    }

    const onFilterClick = () => {
        const bid = data.pataraBid
        setFilterInvisible(true);
        setFormValue(bid);
        dispatch(updatePataraBids([{ bid, id, keyword, currBid }]));
        setInputClass(classname);
        setDisabled(false);
    }

    let change = (((formValue / currBid) - 1) * 100);

    return (
        <div>
            <div className={divClass}>
                <div className="w-3"><button hidden={filterInvisible} onClick={onFilterClick}><MdOutlineUpdate /></button></div>
                <div className='mr-2 p-1 text-xxs w-6 '>{
                    change > 0.5
                        ? <div> < FcBullish /> {change < 9999 ? change.toFixed(0) : "∞"}%  </div>
                        : (
                            change < -0.5
                                ? <div> < FcBearish /> {(change * -1).toFixed(0)}%  </div>
                                : <div> <FcMinus /> --- </div>
                        )
                }
                </div>
                {/* PATARA BID HERE */}
                <input type='number' step={0.01} className={inputClass} value={formValue} onChange={onChange} ></input>
                <button className='my-2 ml-2 mr-1 p-1' onClick={onClick} disabled={disabled || res.isLoading} >
                    {res.isLoading ? <GoSync className="animate-spin" /> : (res.isSuccess ? res.data.success.length > 0 ? <FcOk /> : <FcHighPriority /> : (res.isError ? <FcHighPriority /> : <MdBackup />))}
                </button>
            </div>
            <div className='text-xs flex flex-row justify-center p-1 border-t text-sky-800'>
                {currBid === null ? '-' : currBid ? "$" + currBid.toFixed(2) : '-'}
            </div>
            <div className='text-5xs flex flex-row justify-center text-gray-400'>
                {rules}
            </div>
            <div className='text-5xs flex flex-row justify-center text-gray-400'>
                {bids}
            </div>
        </div>
    );
}

/*
<div className='text-xxs flex flex-row justify-center text-gray-400'>
                {row.avgPriceCmp ? <div>${row.avgPriceCmp.toFixed(2)}</div> : <div>Not Available</div>}
            </div>

*/