import { useSelector } from "react-redux";
import SelectAccountDiv from "../SelectAccountDiv"
import ParentsList from "./ParentsList"

function ParentsPage() {
    const accountId = useSelector(state => state.accounts.accountId);

    return (
        <div>
            {accountId
                ? <div className="my-5">
                    <div className="flex justify-center m-3 py-2 border rounded-xl font-bold shadow">Parent Listings</div>
                    <div className="p-5"><ParentsList accountId={accountId}></ParentsList></div>
                </div>
                : <SelectAccountDiv></SelectAccountDiv>
            }

        </div>
    )
}
export default ParentsPage