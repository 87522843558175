import { useState } from "react"
import { IoDuplicate } from "react-icons/io5"
import Modal from "../Modal"
import NewTask from "./NewTask"

export default function DuplicateTask({ task, refetch, accountId }) {
    const [showModal, setShowModal] = useState(false)
    console.log(task)

    function onChange() {
        setShowModal(false);
    }

    const modal = <Modal onChange={onChange} >
        <NewTask setShowModal={setShowModal} accountId={accountId} refetch={refetch} taskDefault={task}></NewTask>
    </Modal>
    return (
        <div>
            {showModal && modal}
            <button onClick={() => setShowModal(true)}>
                <div className="flex flex-row items-center">
                    <div className="mr-1">Duplicate</div>
                    <div className="text-xl"><IoDuplicate></IoDuplicate></div>
                </div>
            </button>
        </div>
    )
}