//import AccountDropdown from "./AccountDropdown";
import patara from './photos/patara.png';
import Link from "./Link";
//import { updateAccount, useFetchUserQuery } from "../store";
//import { useDispatch } from "react-redux";

function Header2() {
    //const dispatch = useDispatch();
    //const { data, error, isFetching } = useFetchUserQuery();

    //const oauthURI = "https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.7e774af6-2a4b-4daa-a48b-fe3ef49d489a&state=amz12345stateValue&version=beta"
    const oauthURI = "/"
    const items = (

        <div className='flex flex-row'>
            <div className="flex flex-row justify-between items-center mx-1">
                <Link key={'/admin'} to={'/admin'}
                    className='flex py-3 px-2 w-32 shadow text-white rounded-md bg-sky-600 justify-center'
                    activeClassName='flex py-3 px-2 w-32 shadow text-white rounded-md bg-sky-600 justify-center' >{'Admin Console'}
                </Link>
            </div>
            <div className="flex flex-row justify-between items-center mx-1">
                <a key={'/authorize'} href={oauthURI} referrerPolicy="no-referrer"
                    className='flex py-3 px-2 w-32 shadow text-white rounded-md bg-orange-600 justify-center'
                    activeClassName='flex py-3 px-2 w-32 shadow text-white rounded-md bg-orange-600 justify-center' >{'Authorize'}
                </a>
            </div>
        </div>

    );

    let renderedLinks;

    const links = [
        { label: 'Home', path: '/' },
        { label: 'Services', path: '/services' },
    ];

    renderedLinks = links.map((link) => {
        return <Link key={link.path} to={link.path} className='mx-4' activeClassName='font-bold border-b-4 border-black-500' >{link.label}</Link>
    });

    return (
        <div className="flex flex-col sticky inset-x-0 top-0">
            <div className="h-[10vh] flex flex-row justify-between items-center px-5 py-2 bg-sky-50" >
                <div className="flex flex-row justify-between items-center">
                    <div className="w-36 h-18" >
                        <a href="/" ><img src={patara} href="/" alt="patara logo" /></a>
                    </div>
                    <div className='mx-10' >
                        <h1 className='font-bold' >Patara Consulting</h1>
                        <div className='flex items-center' >
                            <h3>Amazon Consulting Agency</h3>
                        </div>
                    </div>
                </div>
                {items}
            </div>
            <div className="h-[4vh] px-5 py-3 flex flex-row items-center bg-sky-50 border-t justify-center" >
                {renderedLinks}
            </div>
        </div>
    );
}

export default Header2;