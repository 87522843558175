import { useState } from "react"
import { RiRefreshFill } from "react-icons/ri";

export default function NGForm({ onSubmit1, guesses, answers, onClick }) {
    const [number, setNumber] = useState("")
    const [className, setClassName] = useState("")
    const [numbers, setNumbers] = useState([])
    let className1 = "border w-36 text-xl rounded-xl px-2 text-center tracking-[0.2em]"
    const onChange = (event) => {
        if (!isNaN(event.target.value) && event.target.value.length < 5) {
            setNumber(event.target.value)
            setNumbers([event.target.value.charAt(0), event.target.value.charAt(1), event.target.value.charAt(2), event.target.value.charAt(3)])
        }
    }
    //console.log("1: ", number1, ", 2: ", number2, ", 3: ", number3, ", 4: ", number4)
    //console.log(numbers)
    const onSubmit = (event) => {
        event.preventDefault()
        const len = number.length === 4
        const num = !isNaN(number)
        if (len && num) {
            if (numbers[0] !== numbers[1] && numbers[0] !== numbers[2] && numbers[0] !== numbers[3] && numbers[1] !== numbers[2] && numbers[1] !== numbers[3] && numbers[2] !== numbers[3]) {
                let plus = 0
                let minus = 0
                let result = ""
                for (let i = 0; i < numbers.length; i++) {
                    if (numbers[i] === answers[i]) {
                        plus++
                    } else if (answers.find(answer => { return numbers[i] === answer })) {
                        minus++
                    }
                }
                for (let i = 0; i < plus; i++) {
                    result = result + "+"
                }
                for (let i = 0; i < minus; i++) {
                    result = result + "-"
                }


                if (result === "++++") {
                    setClassName(" bg-green-600 rounded-xl text-white p-1")
                    console.log(result)
                } else setClassName("")
                onSubmit1({ number, result })
                setNumber("")
            }
        }
    }

    const guessesDiv = guesses.map((guess, index) => {
        if (index === guesses.length - 1) {
            return <div key={index} className={"m-1 p-1" + className}>{guess.number + "  " + guess.result}</div>
        }
        else return <div key={index} className="m-1 p-1">{guess.number + "  " + guess.result}</div>
    })

    return <div className="justify-items-center">
        <div onClick={onClick} className="fixed top-20 right-20 mx-3 text-2xl hover:cursor-pointer w-fit"><RiRefreshFill></RiRefreshFill></div>
        <div className="p-1 ">
            <form onSubmit={onSubmit}>
                <input className={className1} value={number} onChange={onChange}></input>
            </form>
        </div>
        <div className="text-base m-3">
            {guessesDiv}
        </div>
    </div>
}
