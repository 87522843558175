import { useMemo } from 'react';
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { useTable, useSortBy, useRowSelect } from 'react-table';
import { GROUPED_COLUMNS } from './targetingColumns2';
import './targetingtable.css'
import { Checkbox } from '../Checkbox';
import TargetingTableBulkUpdate from './TargetingTableBulkUpdate';
//import { ScrollToTop } from '../ScrollToTop';


export default function TargetingTable({ data, campaignId, avgPriceCmp }) {
    const columns = useMemo(() => GROUPED_COLUMNS, []);
    const tableData = useMemo(() => data, [data]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        toggleAllRowsSelected
    } = useTable({
        columns,
        data: tableData,
        initialState: {
            sortBy: [
                {
                    id: 'spend_4',
                    desc: true
                }
            ]
        },
        disableSortRemove: true,
    },
        useSortBy,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id: 'selection',
                        Header: ({ getToggleAllRowsSelectedProps }) => <div className='flex justify-center p-1  sticky left-0'><Checkbox {...getToggleAllRowsSelectedProps()} /></div>,
                        Cell: ({ row }) => <div className='flex justify-center p-1'><Checkbox {...row.getToggleRowSelectedProps()} /></div>
                    },
                    ...columns
                ]
            })
        });

    const deselectAll = () => {
        toggleAllRowsSelected(false);
    };

    return (
        <div className=''>
            <div className='z-20 sticky top-0'>
                <TargetingTableBulkUpdate selectedFlatRows={selectedFlatRows} deselectAll={deselectAll} campaignId={campaignId} type={'sp'} avgPriceCmp={avgPriceCmp} />
            </div>
            <div className='max-h-screen3/4 overflow-scroll rounded-xl border text-xs'>
                <table {...getTableProps()} className='targeting' >
                    <thead className='sticky z-10 top-0 drop-shadow'>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} >
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className="cursor-pointer hover:bg-sky-900 select-none" >
                                        <div className='flex items-center justify-center'>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? <GoChevronDown /> : <GoChevronUp />) : ''}
                                            </span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}  >
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} >
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>

    );
};