import BudgetForm from "./BudgetForm";
import AcosDisplay from "./AcosDisplayDiv";
import { useSelector } from "react-redux";
import BudgetUsage from "./BudgetUsage";

export const GROUPED_COLUMNS = [

    {
        Header: 'Campaign Name',
        Footer: ({ column }) => {
            //console.log(column)
            return <div>Total Info Div</div>
        },
        accessor: 'campaignName',
        Cell: ({ row, value }) => {
            const lastUpdated = row.original.lastUpdated
            const budgetUpdated = row.original.budgetUpdated
            const accountId = useSelector(state => state.accounts.accountId);
            return (
                <div className="pl-1 break-words">
                    <div>
                        <a href={"/targeting?campaignid=" + row.original.campaignId + "&accountid=" + accountId} rel='noreferrer' target="_blank" className='hover:text-sky-600'>
                            {value}
                        </a>
                    </div>
                    <div className="text-gray-300 text-xxs">{"Campaign ID: " + row.original.campaignId}</div>
                    <div className="text-gray-300 text-xxs">{"Portfolio ID: " + row.original.portfolioId}</div>
                    <div className="border rounded-lg my-1 p-1">
                        <div className="text-gray-700 text-xxs">{lastUpdated ? "Last Updated: " + lastUpdated.date : "Last Updated: Never"}</div>
                    </div>
                    <div className="border rounded-lg p-1">
                        <div className="text-gray-700 text-xxs">{budgetUpdated ? "Budget Updated: " + budgetUpdated.date + " ----- $" + budgetUpdated.oldBudget + " >>> $" + budgetUpdated.newBudget : "Budget Updated: Never"}</div>
                    </div>
                </div>
            )
        }
    },
    {
        Header: 'Budget',
        Footer: 'Budget',
        accessor: 'currBudget',
        Cell: ({ row, value }) => {
            const accountId = useSelector(state => state.accounts.accountId);
            const campaignId = row.original.campaignId
            return <div>
                <BudgetUsage accountId={accountId} campaignId={campaignId}></BudgetUsage>
                <div className='flex justify-center'><BudgetForm key={row.original.campaignId} target={value} char={"$"} campaignId={row.original.campaignId} campaignType="sp"></BudgetForm></div>
                <div className='flex justify-center text-xxs text-gray-400 mt-1'>${row.original.spendPerDay.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            </div>
        }
    },
    {
        Header: 'Placement',
        Footer: 'Placement',
        accessor: 'bidTos',
        Cell: ({ row }) => <div className="flex flex-col text-xxs px-1">
            <div>{"TOS: " + row.original.bidTos}</div>
            <div>{"PDP: " + row.original.bidPdp}</div>
            <div>{"REST: " + row.original.bidRest}</div>
        </div>
    },
    {
        Header: 'ACOS',
        Footer: 'ACOS',
        accessor: 'acos_1',
        sortDescFirst: true,
        Cell: ({ value, row }) => {
            return <AcosDisplay value={value} row={row}></AcosDisplay>
        }
    },
    {
        Header: 'Spend',
        Footer: 'Spend',
        accessor: 'spend_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end font-bold'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
    },
    {
        Header: 'Orders',
        Footer: 'Orders',
        accessor: 'orders_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
    },
    {
        Header: 'Sales (1d)',
        Footer: 'Sales (1d)',
        accessor: 'sales1_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
    },
    {
        Header: 'Sales (7d)',
        Footer: 'Sales (7d)',
        accessor: 'sales7_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end font-bold'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
    },
    {
        Header: 'Sales (14d)',
        Footer: 'Sales (14d)',
        accessor: 'sales_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
    },
    {
        Header: 'Sales (30d)',
        Footer: 'Sales (30d)',
        accessor: 'sales30_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
    },
    {
        Header: 'Clicks',
        Footer: 'Clicks',
        accessor: 'clicks_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
    },
    {
        Header: 'Impress',
        Footer: 'Impress',
        accessor: 'impressions_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
    },
    {
        Header: 'CTR %',
        Footer: 'CTR %',
        accessor: 'ctr_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex justify-center'>{value === 0 ? '-' : (value + "%")}</div>
    },
    {
        Header: 'CPC',
        Footer: 'CPC',
        accessor: 'cpc_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
    },

    /*
    {
        Header: 'Last 10 Days',
        Footer: 'Last 10 Days',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_4',
                sortDescFirst: true,
                Cell: ({ value }) => {
                    const acos = Number(value);
                    return acos > 50
                        ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                        : (acos < 20 && acos > 0
                            ? <div className='flex justify-center bg-green-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                            : <div className='flex justify-center'>{acos === 0 ? '-' : (value + "%")}</div>
                        )
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'spend_4',
                id: "spend_4",
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + value.toFixed(1)}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : value.toFixed(0)}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + value.toFixed(1)}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : value.toFixed(0)}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value.toFixed(0)}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
    {
        Header: 'Last 20 Days',
        Footer: 'Last 20 Days',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_8',
                sortDescFirst: true,
                Cell: ({ value }) => {
                    const acos = Number(value);
                    return acos > 50
                        ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                        : (acos < 20 && acos > 0
                            ? <div className='flex justify-center bg-green-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                            : <div className='flex justify-center'>{acos === 0 ? '-' : (value + "%")}</div>
                        )
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'spend_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + value.toFixed(1)}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : value.toFixed(0)}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + value.toFixed(1)}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : value.toFixed(0)}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value.toFixed(0)}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
    {
        Header: 'Last 30 Days',
        Footer: 'Last 30 Days',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_13',
                sortDescFirst: true,
                Cell: ({ value }) => {
                    const acos = Number(value);
                    return acos > 50
                        ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                        : (acos < 20 && acos > 0
                            ? <div className='flex justify-center bg-green-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                            : <div className='flex justify-center'>{acos === 0 ? '-' : (value + "%")}</div>
                        )
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'spend_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + value.toFixed(1)}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : value.toFixed(0)}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + value.toFixed(1)}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : value.toFixed(0)}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value.toFixed(0)}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
    */
]