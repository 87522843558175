import AccountDropdown from "./AccountDropdown";
import patara from './photos/patara.png';
import { updateAccount, useFetchUserQuery, updateCampaignId, updatePortfolioId } from "../store";
import { useDispatch } from "react-redux";

function Header() {
    const dispatch = useDispatch();
    const { data, error } = useFetchUserQuery();

    const setAccountId = (account) => {
        dispatch(updateAccount(account));
    };
    const updateCampaign = (campaign) => {
        dispatch(updateCampaignId(campaign));
    };
    const updatePortfolio = (portfolio) => {
        dispatch(updatePortfolioId(portfolio));
    };

    let items = (
        <div className="flex flex-row justify-between items-center">
            <a href="/auth/google" >
                <div className='flex py-3 px-2 w-32 shadow text-white rounded-md bg-green-600 justify-center' >
                    Log in
                </div>
            </a>
        </div>
    );

    if (error) {
        console.log(error);
        if (error.data.error) {
            items = (
                <div className="flex flex-row justify-between items-center">
                    <div className="mx-5 px-3 text-sm" >
                        <div>{error.data.error}</div>
                    </div>
                    <div className="flex flex-row justify-between items-center">
                        <a href="/auth/google" >
                            <div className='flex py-3 px-2 w-32 shadow text-white rounded-md bg-green-600 justify-center' >
                                Log in
                            </div>
                        </a>
                    </div>
                </div>
            );
        } else if (error.data.googleEmails) {
            items = (
                <div className="flex flex-row justify-between items-center">
                    <div className="mx-5 px-3 text-sm" >
                        <div>{error.data.googleName}</div>
                        <div>{error.data.googleEmails[0].value}</div>
                    </div>
                    <div className="mx-5 px-3 text-sm" >
                        <div>Not Authorized</div>
                    </div>
                    <div className="flex flex-row justify-between items-center">
                        <a href="/api/logout" >
                            <div className='flex py-3 px-2 w-32 shadow rounded-md bg-sky-100 justify-center' >
                                Log out
                            </div>
                        </a>
                    </div>
                </div>
            );
        }

    } else if (data) {
        items = (
            <div className="flex flex-row justify-between items-center">
                <img src={data.googlePhotos[0].value} alt='profile pic' className="rounded-full h-16 w-16 drop-shadow"></img>
                <div className="mx-2 text-sm" >
                    <div>{data.googleName}</div>
                    <div>{data.googleEmails[0].value}</div>
                </div>
                <div className="mx-3" >
                    <AccountDropdown
                        setAccountId={setAccountId}
                        updateCampaign={updateCampaign}
                        updatePortfolio={updatePortfolio}
                    />
                </div>
                <div >
                    <a href="/api/logout" >
                        <div className='flex py-3 px-2 w-32 shadow rounded-md bg-sky-100 justify-center' >
                            Log out
                        </div>
                    </a>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-row justify-between items-center px-5 py-2 bg-sky-50" >
            <div className="flex flex-row justify-between items-center">
                <div className="w-36 h-18" >
                    <a href="/admin" ><img src={patara} href="/admin" alt="patara logo" /></a>
                </div>
                <div className='mx-10 shadow px-2 py-1 rounded-xl selection:bg-yellow-500 selection:text-white' >
                    <h1 className='font-bold' >PataraWeb</h1>
                    <div className='flex items-center' >
                        <h3>Amazon Account Management</h3>
                    </div>
                    <div className='flex items-center justify-end text-gray-400 text-xs' >
                        v.1.15.62
                    </div>
                </div>
            </div>
            {items}
        </div>
    );
}

export default Header;