import { useSelector } from "react-redux";
import { useState } from "react";
import { GoSync } from "react-icons/go";
import "./enableToggle.css"
import { useUpdateParentStateMutation } from "../../store";

function ToggleSwitch({ isChecked, asin }) {
    const accountId = useSelector(state => state.accounts.accountId);
    const [updateState, res] = useUpdateParentStateMutation()
    const [disabled, setDisabled] = useState(false)
    const [checked, setChecked] = useState(isChecked)

    const onChange = async (event) => {
        setDisabled(true)
        setChecked(event.target.checked)
        console.log(event.target.checked)
        console.log(asin)
        console.log(accountId)
        await updateState({ state: event.target.checked, id: asin, accountId })
        setDisabled(false)
    }

    return (
        <div className="flex justify-items-center">
            {
                res.isUninitialized
                    //ilk başlangıç
                    ? <label className="switch2">
                        <input type="checkbox" onChange={onChange} defaultChecked={isChecked} disabled={disabled}></input>
                        <span className="slider2 round2 switch2"></span>
                    </label>
                    : res.isLoading
                        // yükleniyor
                        ? <GoSync className="animate-spin" />
                        : res.isSuccess
                            // başarılı oldu
                            ? <div className="flex flex-row items-center bg-green-200 p-2 rounded">
                                <label className="switch2">
                                    <input type="checkbox" onChange={onChange} defaultChecked={checked} disabled={disabled}></input>
                                    <span className="slider2 round2 switch2"></span>
                                </label>
                            </div>
                            //başarısız oldu
                            : <div className="flex flex-row bg-red-200 p-2 rounded">
                                <label className="switch2">
                                    <input type="checkbox" onChange={onChange} defaultChecked={!checked} disabled={disabled}></input>
                                    <span className="slider2 round2 switch2"></span>
                                </label>
                            </div>
            }
        </div>


    );
}

export default ToggleSwitch;