import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HiMagnifyingGlass } from "react-icons/hi2";
import PortfoliosDropdown from "../PortfoliosDropdown";
import CampaignsList from "./CampaignsList";
import DateRangePickerCustom from "../DateRangePickerCustom";
import SelectAccountDiv from "../SelectAccountDiv"
import { updateSelectedDate } from "../../store";

function CampaignsPage() {
    const accountId = useSelector(state => state.accounts.accountId);
    const [portfolioId, setPortfolioId] = useState(0);
    const [buttonColor, setButtonColor] = useState(0);
    const [searchText, setSearchText] = useState()

    const dispatch = useDispatch()
    const selectedDate = useSelector(state => state.accounts.selectedDateCampaigns);
    const setSelectedDate = (selectedDate) => dispatch(updateSelectedDate(selectedDate))

    const dateNow = new Date();
    let start = new Date();
    let end = new Date();
    if (selectedDate) {
        start = new Date(selectedDate.endDate)
        end = new Date(selectedDate.startDate)
    } else {
        const offset = new Date().getTimezoneOffset()
        start.setDate(dateNow.getDate() - (2));
        end.setDate(dateNow.getDate() - (31));
        start = new Date(start.getTime() + ((offset - 360) * 60 * 1000))
        end = new Date(end.getTime() + ((offset - 360) * 60 * 1000))
    }

    const [pickedDate, setPickedDate] = useState([
        {
            startDate: end,
            endDate: start,
            key: 'selection'
        }
    ]);
    //console.log(pickedDate[0]);


    //butonlar için tarih hesaplama - karşılaştırma için CampaignList sayfasına bak
    const onClick = (days) => {
        let start = new Date();
        let end = new Date();
        const offset = new Date().getTimezoneOffset()
        start.setDate(dateNow.getDate() - (2));
        end.setDate(dateNow.getDate() - (days + 1));
        start = new Date(start.getTime() + ((offset - 360) * 60 * 1000))
        end = new Date(end.getTime() + ((offset - 360) * 60 * 1000))
        setButtonColor(days)
        setPickedDate([{ startDate: end, endDate: start, key: "selection" }])
        setSelectedDate({ startDate: end.toString(), endDate: start.toString(), key: "selection" })
    }

    const onSearchSubmit = (e) => {
        e.preventDefault()
        console.log(e.target[0].value)
        setSearchText(e.target[0].value)
    }

    const buttonClass = "rounded-xl shadow mx-1 px-2 border font-bold h-12 w-16 bg-sky-100"
    const buttonSelectedClass = "rounded-xl shadow mx-1 px-2 border h-12 w-16"
    const searchClass = "rounded-xl border px-2 py-2 w-64"

    return (
        <div>
            {accountId
                ? <div className="flex flex-col h-screen">
                    < div className="flex px-5 py-1" >
                        <PortfoliosDropdown portfolioId={portfolioId} setPortfolioId={setPortfolioId} />
                        <DateRangePickerCustom setSelectedDate={setSelectedDate} setPickedDate={setPickedDate} pickedDate={pickedDate} setButtonColor={setButtonColor} defaultText="Pick a Date"></DateRangePickerCustom>
                        <div className="flex flex-row items-center mx-2" id="timeframe-buttons">
                            {buttonColor === 7
                                ? <button onClick={() => onClick(7)} className={buttonClass}>7</button>
                                : <button onClick={() => onClick(7)} className={buttonSelectedClass}>7</button>
                            }

                            {buttonColor === 15
                                ? <button onClick={() => onClick(15)} className={buttonClass}>15</button>
                                : <button onClick={() => onClick(15)} className={buttonSelectedClass}>15</button>
                            }

                            {buttonColor === 30
                                ? <button onClick={() => onClick(30)} className={buttonClass}>30</button>
                                : <button onClick={() => onClick(30)} className={buttonSelectedClass}>30</button>
                            }

                            {buttonColor === 60
                                ? <button onClick={() => onClick(60)} className={buttonClass}>60</button>
                                : <button onClick={() => onClick(60)} className={buttonSelectedClass}>60</button>
                            }

                            {buttonColor === 90
                                ? <button onClick={() => onClick(90)} className={buttonClass}>90</button>
                                : <button onClick={() => onClick(90)} className={buttonSelectedClass}>90</button>
                            }
                        </div>
                        <div className="flex items-center">
                            <form onSubmit={onSearchSubmit} className="flex flex-row">
                                <div className="flex flex-col">
                                    <input id="searchbar" className={searchClass} type="text" placeholder={"type to search"}></input>
                                </div>
                                <button className="mx-1 text-lg" type="submit"><HiMagnifyingGlass /></button>
                            </form>
                        </div>
                    </div >
                    <CampaignsList portfolioId={portfolioId} pickedDate={pickedDate[0]} accountId={accountId} searchText={searchText} />
                </div >
                : <SelectAccountDiv></SelectAccountDiv>
            }
        </div>

    );
}

export default CampaignsPage;