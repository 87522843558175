import { useFetchUserQuery, useFetchQuoteQuery } from "../../store";

function WelcomePage() {
    const { data, error } = useFetchUserQuery()
    const { data: dataQuote } = useFetchQuoteQuery()

    let renderedContent
    if (error) { //eğer kullanıcı giriş yapmadıysa
        console.log('fetch user error!!!');
        renderedContent = <div>User not authorized</div>
    } else if (data) { //eğer giriş yapıldıysa
        renderedContent =
            <div className="flex flex-row">
                <div className="flex m-2 rounded-xl shadow">
                    <div className="flex p-2 m-3 items-center">
                        <img src={data.googlePhotos[0].value} alt='profile pic' className="rounded-full h-16 w-16 drop-shadow"></img>
                        <div className="mx-2 text-sm" >
                            <div>{data.googleName}</div>
                            <div>{data.googleEmails[0].value}</div>
                        </div>
                    </div>
                    <div className="flex flex-col p-2 m-3">
                        <div>
                            {renderedContent}
                        </div>
                        <div className="text-yellow-500">
                            <div className="font-bold">Welcome!</div>"Have a great day and have fun!"
                        </div>
                        <div className="mt-3 max-w-prose">
                            {dataQuote ? <div><div className="font-bold">Quote of the Day:</div>"{dataQuote[0].q}"  {dataQuote[0].a}</div> : ""}
                        </div>
                        <div className="mt-3 max-w-prose">
                            {dataQuote ? <div><div className="font-bold">Cat Fact: </div>"{dataQuote[1].fact}"</div> : ""}
                        </div>
                    </div>
                </div>
                <div className="flex bg-sky-50 m-2 rounded-xl shadow">
                </div>
            </div>
    }

    return (
        <div className="px-5 py-3">
            <div className="flex justify-center m-3 py-2 border rounded-xl font-bold shadow">PataraWeb</div>
            {renderedContent}
            <div className="w-fit">
                <a href="/games" ><div className="px-3 text-gray-200 text-xxs">1234</div></a>
            </div>
        </div>
    )
}
export default WelcomePage