import "./performanceToggle.css"

function ToggleSwitch({ isWeekly, setIsWeekly }) {

    const onChange = (event) => {
        setIsWeekly(event.target.checked)
    }
    return (
        <label className="switch3">
            <input type="checkbox" onChange={onChange} defaultChecked={isWeekly}></input>
            <span className="switch3 slider3 round3"></span>
        </label>
    );

}

export default ToggleSwitch;