import { useEffect, useState } from "react";
import Dropdown from "./Dropdown";
import { useFetchPortfoliosQuery } from "../store";
import { useSelector } from "react-redux";

function PortfoliosDropdown({ portfolioId, setPortfolioId }) {
    const accountId = useSelector(state => state.accounts.accountId);
    const [selectedPrtf, setSelectedPrtf] = useState(null);
    const resPrtf = useFetchPortfoliosQuery(accountId);

    //account değiştiği zaman dropdown güncellensin diye
    useEffect(() => {
        setSelectedPrtf(null);
        setPortfolioId(0);
    }, [accountId, setPortfolioId]);

    const onPrtfChange = (item, id) => {
        setSelectedPrtf(item);
        setPortfolioId(id);
    };

    let portfolioDropdown;
    if (resPrtf.isFetching) {
        portfolioDropdown = <Dropdown options={[]}
            onChange={(newItem) => setSelectedPrtf(newItem)}
            value={selectedPrtf} className="w-64 text-sm" defaultText="Loading..." />
    } else if (resPrtf.error) {
        console.log(resPrtf.error);
        portfolioDropdown = <Dropdown options={[]}
            onChange={(newItem) => setSelectedPrtf(newItem)}
            value={selectedPrtf} className="w-64 text-sm" defaultText="Error!" />
    } else if (resPrtf.data) {
        const dropdownOptions = resPrtf.data
            .filter(portfolio => {
                return portfolio.state === "enabled";
            })
            .map(portfolio => {
                return {
                    label: portfolio.name,
                    value: portfolio.portfolioId
                }
            });

        let sortedDropdownOptions = [...dropdownOptions].sort((a, b) => {
            const valueA = a.label;
            const valueB = b.label;
            return valueA.localeCompare(valueB);
        });

        sortedDropdownOptions.unshift({ label: 'All Portfolios', value: 0 });

        portfolioDropdown = <Dropdown options={sortedDropdownOptions} id={portfolioId}
            onChange={onPrtfChange} className="w-64 text-sm"
            value={selectedPrtf} defaultText="All Portfolios" />
    }

    return (
        <div className="flex flex-row h-12">
            <div className="mr-3">{portfolioDropdown}</div>
        </div>
    );
}

export default PortfoliosDropdown;