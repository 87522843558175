import { useFetchListingsFilteredQuery } from "../../store"
import { FiDownload } from "react-icons/fi"
import { BiSortAlt2 } from "react-icons/bi"
import { IoCopyOutline } from "react-icons/io5";
import Skeleton from '../Skeleton';
import SameMethod from "./SameMethodList";
import NoBuyBox from "./NoBuyBoxList";
import Hijacker from "./HijackerList"

export default function ListingsList({ accountId, parent, filter, setFilter }) {
    const { data, isFetching } = useFetchListingsFilteredQuery({ accountId, parent })

    let listingsDiv = <div className="shadow p-2 rounded-xl border">No Data Received</div>
    let infoDiv = <div></div>

    const onFilterClick = () => {
        if (filter.id === "az") setFilter({ label: "Sales", id: "sales" })
        else setFilter({ label: "A-Z", id: "az" })
    }

    if (isFetching) {
        listingsDiv = <Skeleton times={10} className="h-10 w-full" />;
    } else if (data) {
        const dateNow = new Date();
        let dateArray = [], dateStartArray = [], dateEndArray = [];
        for (let i = 0; i < 90; i++) {
            let start = new Date();
            let end = new Date();
            const offset = new Date().getTimezoneOffset()
            start.setDate(dateNow.getDate() - (2 + i));
            end.setDate(dateNow.getDate() - (2 + i));
            start = new Date(start.getTime() + (offset * 60 * 1000))
            end = new Date(end.getTime() + (offset * 60 * 1000))
            dateStartArray[i] = start.toISOString().split('T')[0];
            dateEndArray[i] = end.toISOString().split('T')[0];
            dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
            //console.log('Week ', i, ': Start Date: ', dateStartArray[i], ', End Date: ', dateEndArray[i]);
        }
        if (data.length > 0) {
            const data1 = data.map((asin) => {
                asin = {
                    ...asin,
                    sameMethod: false
                }
                let totalQuantity = 0
                asin.skus.forEach((sku1, index2) => {
                    if (index2 === 1 && sku1.fulfillment_channel === asin.skus[0].fulfillment_channel) {
                        //console.log("same method: ", asin.asin)
                        asin.sameMethod = true
                    } else if (index2 > 1 && asin.sameMethod) {
                        asin.skus.forEach((sku2, index3) => {
                            if (index3 !== index2 && sku1.fulfillment_channel !== sku2.fulfillment_channel) {
                                //console.log("changed sameMethod: ", asin.asin)
                                asin.sameMethod = false
                            }
                        })
                    }
                    if (sku1.fulfillment_channel === "DEFAULT" || sku1.fba_condition === "NewItem") {
                        if (sku1.inventory_details) totalQuantity = totalQuantity + sku1.inventory_details.fulfillableQuantity
                        else totalQuantity = totalQuantity + sku1.quantity
                    }
                })
                asin = {
                    ...asin,
                    totalQuantity
                }
                return asin
            })
            let newData
            if (parent) {
                newData = data1.filter(asin => asin.parentAsin === parent)
            } else {
                newData = data1
            }
            let oosSales = 0, allSales = 0, oosUnitsOrdered = 0, allUnitsOrdered = 0
            newData = newData.map(asin => {
                let totalSales = 0, totalUnitsOrdered = 0
                asin.performance.forEach(performance => {
                    for (let i = 0; i < 30; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            totalSales = totalSales + performance.orderedProductSales
                            totalUnitsOrdered = totalUnitsOrdered + performance.unitsOrdered
                        }
                    }
                })
                /*
                for (let i = 0; i < 30; i++) {
                    if (asin.performance[asin.performance.length - (i + 1)]) {
                        totalSales = totalSales + asin.performance[asin.performance.length - (i + 1)].orderedProductSales
                        totalUnitsOrdered = totalUnitsOrdered + asin.performance[asin.performance.length - (i + 1)].unitsOrdered
                    }
                }
                */
                allSales = allSales + totalSales
                allUnitsOrdered = allUnitsOrdered + totalUnitsOrdered
                if (asin.totalQuantity === 0) {
                    oosSales = oosSales + totalSales
                    oosUnitsOrdered = oosUnitsOrdered + totalUnitsOrdered
                }
                return {
                    ...asin,
                    totalSales,
                    totalUnitsOrdered
                }
            })
            if (filter.id === "az") {
                newData.sort(function (a, b) {
                    let x = a.skus.find(sku => !sku.seller_sku.includes("amzn.gr.")).seller_sku.toLowerCase();
                    let y = b.skus.find(sku => !sku.seller_sku.includes("amzn.gr.")).seller_sku.toLowerCase();
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                })
            } else if (filter.id === "sales") {
                newData.sort(function (a, b) {
                    return b.totalSales - a.totalSales
                })
            }
            let sameMethodDiv = [], sameMethodList = [], noBuyBoxDiv = [], noBuyBoxList = [], hijackerDiv = [], hijackerList = []
            listingsDiv = newData.map((asin, index1) => {
                let skusDiv = asin.skus.filter(sku => {
                    return sku.fulfillment_channel === "DEFAULT" || sku.fba_condition === "NewItem" || (!sku.fba_condition && sku.status === "Active")
                }
                ).map((listing, index2) => {
                    return (
                        <div key={index2} className="flex flex-row">
                            <div className="text-xs p-2 basis-15p break-words overflow-hidden ">{listing.seller_sku}</div>
                            <div className="text-xs p-2 basis-7p break-words overflow-hidden flex justify-center ">${listing.price}</div>
                            <div className="text-xs p-1 basis-5p break-words overflow-hidden ">
                                {listing.fulfillment_channel === 'DEFAULT'
                                    ? <input readOnly className="w-[4vw] p-1 px-3 border rounded-xl" value={listing.quantity}></input>
                                    : <div className="w-[4vw] p-1 px-3 rounded-xl">{(listing.inventory_details && listing.inventory_details.fulfillableQuantity) || (0)}</div>}
                            </div>
                            <div className="text-xs p-2 basis-5p break-words overflow-hidden flex justify-center ">{listing.fulfillment_channel === 'DEFAULT' ? "FBM" : "FBA"}</div>
                            {
                                listing.status === "Active"
                                    ? <div className="text-xs p-2 basis-5p break-words overflow-hidden justify-center text-green-700 font-bold">{listing.status}</div>
                                    : <div className="text-xs p-2 basis-5p break-words overflow-hidden justify-center font-bold">{listing.status}</div>
                            }

                            <div className="text-xs p-2 basis-8p break-words overflow-hidden justify-center">{listing.listing_id}</div>
                            <div className="text-xs p-1 basis-8p overflow-hidden ">
                                {listing.fulfillment_channel === 'DEFAULT'
                                    ? <div className="px-1">-</div>
                                    : <div className="w-[4vw] p-1 px-1 rounded-xl">{listing.fba_condition}</div>}
                            </div>
                            <div className="text-xs p-1 basis-5p break-words overflow-hidden ">
                                {listing.fulfillment_channel === 'DEFAULT'
                                    ? <div className="px-3">-</div>
                                    : <div className="w-[4vw] p-1 px-3 rounded-xl">{(listing.inventory_details && listing.inventory_details.totalReservedQuantity) || (0)}</div>}
                            </div>
                            <div className="text-xs p-1 basis-5p break-words overflow-hidden ">
                                {listing.fulfillment_channel === 'DEFAULT'
                                    ? <div className="px-3">-</div>
                                    : <div className="w-[4vw] p-1 px-3 rounded-xl">{(listing.inventory_details && listing.inventory_details.inboundShippedQuantity) || (0)}</div>}
                            </div>
                            <div className="text-xs p-1 basis-5p break-words overflow-hidden ">
                                {listing.fulfillment_channel === 'DEFAULT'
                                    ? <div className="px-3">-</div>
                                    : <div className="w-[4vw] p-1 px-3 rounded-xl">{(listing.inventory_details && listing.inventory_details.inboundReceivingQuantity) || (0)}</div>}
                            </div>
                            <div className="text-xs p-1 basis-5p break-words overflow-hidden ">
                                {listing.fulfillment_channel === 'DEFAULT'
                                    ? <div className="px-3">-</div>
                                    : <div className="w-[4vw] p-1 px-3 rounded-xl">{(listing.inventory_details && listing.inventory_details.reservedQuantity.fcProcessingQuantity) || (0)}</div>}
                            </div>
                            <div className="text-xs p-1 basis-5p break-words overflow-hidden ">
                                {listing.fulfillment_channel === 'DEFAULT'
                                    ? <div className="px-3">-</div>
                                    : <div className="w-[4vw] p-1 px-3 rounded-xl">{(listing.inventory_details && listing.inventory_details.reservedQuantity.pendingCustomerOrderQuantity) || (0)}</div>}
                            </div>
                            <div className="text-xs p-1 basis-5p break-words overflow-hidden ">
                                {listing.fulfillment_channel === 'DEFAULT'
                                    ? <div className="px-3">-</div>
                                    : <div className="w-[4vw] p-1 px-3 rounded-xl">{(listing.inventory_details && listing.inventory_details.reservedQuantity.pendingTransshipmentQuantity) || (0)}</div>}
                            </div>
                        </div>
                    )
                })
                skusDiv.unshift(
                    <div key={-1} className="flex flex-row py-1 bg-white">
                        <div className="flex text-xxs p-2 basis-15p">SKU</div>
                        <div className="flex text-xxs p-2 basis-7p justify-center ">Price</div>
                        <div className="flex text-xxs p-2 basis-5p ">Quantity</div>
                        <div className="flex text-xxs p-2 basis-5p justify-center ">Fulfill.</div>
                        <div className="flex text-xxs p-2 basis-5p ">Status</div>
                        <div className="flex text-xxs p-2 basis-8p ">Listing Id</div>
                        <div className="flex text-xxs p-2 basis-8p ">FBA Condition</div>
                        <div className="flex text-xxs p-2 basis-5p ">Reserved</div>
                        <div className="flex text-xxs p-2 basis-5p ">Inbound Shp</div>
                        <div className="flex text-xxs p-2 basis-5p ">Inbound Rcv</div>
                        <div className="flex text-xxs p-2 basis-5p ">FC Pross.</div>
                        <div className="flex text-xxs p-2 basis-5p ">Pend.Order</div>
                        <div className="flex text-xxs p-2 basis-5p ">Pend.Trnsshp</div>
                    </div>
                )
                let classNameAsin = "text-xs p-2 basis-7p break-words overflow-hidden"
                const onCopyClick = () => {
                    console.log("onCopyClicked")
                    let text = document.getElementById("asin" + asin.asin)
                    text.select()
                    navigator.clipboard.writeText(text.value)
                    console.log(text.value)
                }
                const asinDiv =
                    <div key={index1} className="shadow border rounded-xl my-3">
                        <div className="flex flex-row">
                            <div className="text-xs p-2 w-[6vw] max-h-[12vh] rounded-xl overflow-hidden"><img src={asin.main_image} alt="main_image" className="rounded-xl shadow-lg" /></div>
                            <div className={classNameAsin}>
                                <div className="w-fit">
                                    <a href={"https://www.amazon.com/dp/" + asin.asin} rel='noreferrer' target="_blank">
                                        <div className="bg-yellow-200">
                                            <input className='hover:text-sky-600 hover:cursor-pointer w-fit' id={"asin" + asin.asin} value={asin.asin} readOnly></input>
                                        </div>
                                    </a>
                                    <div className="hover:cursor-pointer w-fit" onClick={onCopyClick}><IoCopyOutline /></div>
                                    <div className="border my-1 w-fit p-1 shadow rounded-md">
                                        <div className="text-3xs text-gray-500">30-Day Sales</div>
                                        <div className="text-xs font-bold">${asin.totalSales.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                                        <div className="text-xxs">{asin.totalUnitsOrdered.toLocaleString(undefined, { maximumFractionDigits: 0 })} Units</div>
                                    </div>
                                    {
                                        asin.totalQuantity === 0
                                            ? <div className="text-red-600 font-bold">Out of Stock</div>
                                            : <div>Quantity: {asin.totalQuantity}</div>
                                    }
                                </div>
                            </div>
                            <div className="text-xs p-2 basis-30p text-justify">{asin.item_name}</div>
                            <div className="text-xs p-2 basis-5p break-words overflow-hidden"><div>{asin.sales_rank}</div><div className="text-xxs">{asin.sales_rank_title}</div></div>
                            <div className="text-xs p-2 basis-5p break-words overflow-hidden"><div>{asin.sales_rank_cat}</div><div className="text-xxs">{asin.sales_rank_cat_title}</div></div>
                            <div className="text-xs p-2 basis-7p break-words overflow-hidden">{asin.parentAsin}</div>
                            <div className="text-xs p-2 basis-5p break-words overflow-hidden">{asin.item_type_keyword}</div>
                            <div className="text-xs p-2 basis-5p break-words overflow-hidden">{asin.product_type}</div>
                            <div className="text-xxs p-2 basis-6p break-words overflow-hidden">{asin.upc || asin.ean}</div>
                            <div className="text-xxs p-2 basis-5p break-words overflow-hidden">{asin.size}</div>
                            <div className="text-xxs p-2 basis-5p break-words overflow-hidden">{asin.color}</div>
                            <div className="text-xxs p-2 basis-5p break-words overflow-hidden">{asin.headwear_size}</div>
                            <div className="text-xxs p-2 basis-5p break-words overflow-hidden">{asin.headwear_size_to}</div>
                        </div>
                        <div className="border-t">
                            {skusDiv}
                        </div>
                    </div>
                if (asin.sameMethod) {
                    sameMethodDiv.push(asinDiv)
                    sameMethodList.push(asin)
                }
                if (asin.noBuyBox) {
                    noBuyBoxDiv.push(asinDiv)
                    noBuyBoxList.push(asin)
                }
                if (asin.hijacker) {
                    hijackerDiv.push(asinDiv)
                    hijackerList.push(asin)
                }
                return asinDiv
            })
            const onDownloadClick = () => {
                //console.log("downloading the csv file")
                let headers = ['', 'ASIN', 'UPC', 'EAN', 'SIZE', 'COLOR', 'TYPE', 'CATEGORY', 'SKUS']
                let listingInfo = newData.map(listing => {
                    return { asin: listing.asin, upc: listing.upc, ean: listing.ean, size: listing.size, color: listing.color, product_type: listing.product_type, item_type_keyword: listing.item_type_keyword, skus: listing.skus }
                })
                let data1 = []

                for (let i = 0; i < headers.length; i++) {
                    if (i === headers.length - 1) {
                        data1.push(headers[i])
                        data1.push('\n')
                    }
                    else data1.push(headers[i])
                }
                for (let i = 0; i < listingInfo.length; i++) {
                    data1.push(listingInfo[i].asin)
                    data1.push(listingInfo[i].upc)
                    data1.push(listingInfo[i].ean)
                    data1.push(listingInfo[i].size)
                    data1.push(listingInfo[i].color)
                    data1.push(listingInfo[i].product_type)
                    data1.push(listingInfo[i].item_type_keyword)
                    listingInfo[i].skus.forEach(sku => {
                        data1.push(sku.seller_sku)
                        data1.push(sku.fulfillment_channel)
                    })
                    data1.push('\n')
                }
                //console.log(data1)

                const url = window.URL.createObjectURL(new Blob([data1]))
                const link = document.createElement('a')
                link.href = url
                const date = new Date().toISOString().split('T')[0]
                const fileName = `Listings ${accountId} ${date}.csv`;
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                link.remove()
            }
            const oosRate = oosSales / allSales * 100
            const oosUnitsRate = oosUnitsOrdered / allUnitsOrdered * 100
            let oosRateDivClass = "shadow rounded-lg px-3 py-1 mx-2"
            if (oosRate > 25) oosRateDivClass = "shadow rounded-lg px-3 py-1 bg-red-600 text-white mx-2"
            let oosUnitsRateDivClass = "shadow rounded-lg px-3 py-1 mx-2"
            if (oosUnitsRate > 25) oosUnitsRateDivClass = "shadow rounded-lg px-3 py-1 bg-red-600 text-white mx-2"
            infoDiv = <div className="flex flex-row py-2 items-center">
                <div className="text-xs p-2 break-words overflow-hidden">{"Number of Listings: " + data.length}</div>
                <SameMethod div={sameMethodDiv} list={sameMethodList} ></SameMethod>
                <NoBuyBox div={noBuyBoxDiv} list={noBuyBoxList}></NoBuyBox>
                <Hijacker div={hijackerDiv} list={hijackerList}></Hijacker>
                <div className="flex flex-row justify-center rounded-lg border shadow px-2 p-1 mx-2 hover:cursor-pointer w-20 text-center" onClick={onFilterClick}>
                    {filter.label}
                    <div className="text-xl mx-1"><BiSortAlt2 /></div>
                </div>
                <div className={oosRateDivClass}>
                    <div className="text-xxs">OOS Rate (Sales)</div>
                    <div className="font-bold">%{(oosRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                </div>
                <div className={oosUnitsRateDivClass}>
                    <div className="text-xxs">OOS Rate (Units)</div>
                    <div className="font-bold">%{(oosUnitsRate).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                </div>

                <div className="flex grow justify-end items-center">
                    {parent ? <div>ASINs: {newData.length}</div> : <div>ASINs: {data.length}</div>}
                    <button className="text-lg mx-2 flex" onClick={onDownloadClick} disabled={listingsDiv.length === 0}><FiDownload></FiDownload></button>
                </div>

            </div>
        }
        else {
            listingsDiv = <div className="shadow p-2 rounded-xl border">No Data Received from Database</div>
        }
    }

    return (
        <div className="min-h-[50vh]">
            <div className="z-10 sticky top-0 bg-white">
                {infoDiv}
                <div className="flex flex-row font-bold py-1 bg-white shadow rounded-xl border">
                    <div className="flex text-s p-2 basis-6p justify-center border-x">Main Image</div>
                    <div className="flex text-s p-2 basis-7p justify-center border-x">ASIN</div>
                    <div className="flex text-s p-2 basis-30p justify-center border-x">Title</div>
                    <div className="flex text-s p-2 basis-5p justify-center border-x">BSR</div>
                    <div className="flex text-s p-2 basis-5p justify-center border-x">BSR Cat</div>
                    <div className="flex text-s p-2 basis-7p justify-center border-x">Parent</div>
                    <div className="flex text-s p-2 basis-5p justify-center border-x">Category</div>
                    <div className="flex text-s p-2 basis-5p justify-center border-x">Type</div>
                    <div className="flex text-s p-2 basis-6p justify-center border-x">UPC/EAN</div>
                    <div className="flex text-s p-2 basis-5p justify-center border-x">Size</div>
                    <div className="flex text-s p-2 basis-5p justify-center border-x">Color</div>
                    <div className="flex text-s p-2 basis-5p justify-center border-x">H Size</div>
                    <div className="flex text-s p-2 basis-5p justify-center border-x">H Sizeto</div>
                </div>
            </div>
            <div className="max-h-[80vh] overflow-scroll ">
                {listingsDiv}
            </div>
        </div>
    )
}