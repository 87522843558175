//import { useSelector } from "react-redux";
import CampaignEnableToggle from "../CampaignEnableToggle2"
import { RiRefreshFill } from "react-icons/ri";

function TargetingTableBulkUpdate({ selectedFlatRows, refetch, campList, budgetUsage, portfolioId, searchText }) {
    //console.log("campaign bulk update page")
    //const isEnableCmpOnly = useSelector(state => state.accounts.isEnableCmpOnly);
    //console.log(isEnableCmpOnly)

    const onSubmitAllClick = async () => {
        console.log("submit clicked")
    };

    //ADD CAMPAIGN BUDGET INFO DIV
    //Display campaigns budget total + used total

    let totalBudget, unfTotalBudget
    if (campList && campList.campaigns) {
        console.log(campList.campaigns)
        totalBudget = 0
        unfTotalBudget = 0
        if (portfolioId && searchText) {
            campList.campaigns.filter(campaign => (campaign.portfolioId === portfolioId.toString() && campaign.name.includes(searchText))).forEach(campaign => {
                totalBudget = totalBudget + campaign.budget.budget
            })
        } else if (portfolioId) {
            campList.campaigns.filter(campaign => campaign.portfolioId === portfolioId.toString()).forEach(campaign => {
                totalBudget = totalBudget + campaign.budget.budget
            })
        } else if (searchText) {
            campList.campaigns.filter(campaign => campaign.name.includes(searchText)).forEach(campaign => {
                totalBudget = totalBudget + campaign.budget.budget
            })
        } else {
            campList.campaigns.forEach(campaign => {
                totalBudget = totalBudget + campaign.budget.budget
            })
        }
        campList.campaigns.forEach(campaign => {
            unfTotalBudget = unfTotalBudget + campaign.budget.budget
        })

    }

    return (
        <div className='flex flex-row items-center p-2 bg-white h-16 w-auto'>
            <div className='p-1 mx-1 w-28' >Selected: {selectedFlatRows.length}</div>
            <button className={selectedFlatRows.length === 0 ? 'p-2 m-2 bg-gray-100 text-gray-400 rounded-lg' : 'p-2 m-2 bg-sky-500 text-white rounded-lg'}
                onClick={onSubmitAllClick} >
                <div className="flex flex-row items-center">
                    Submit All
                </div>
            </button>
            <div className="mx-2"><CampaignEnableToggle></CampaignEnableToggle></div>
            <div onClick={() => refetch()} className="mx-3 text-2xl hover:cursor-pointer w-fit"><RiRefreshFill></RiRefreshFill></div>
            {
                totalBudget
                    ? <div className="border rounded-lg shadow p-1 px-4 mx-3">
                        <div className="flex flex-row items-center">
                            <div className="text-end w-12">
                                ${totalBudget.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                            </div>
                            <div className="text-3xs text-gray-500 w-3 mx-1">
                                (%{(totalBudget / unfTotalBudget * 100).toLocaleString(undefined, { maximumFractionDigits: 0 })})
                            </div>
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="text-end text-gray-500 w-12">
                                ${budgetUsage.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                            </div>
                            <div className="text-3xs text-gray-500 w-3 mx-1">
                                (%{(budgetUsage / totalBudget * 100).toLocaleString(undefined, { maximumFractionDigits: 0 })})
                            </div>
                        </div>


                    </div>
                    : <div></div>
            }


        </div>
    );
}

export default TargetingTableBulkUpdate;