import { useSelector, useDispatch } from "react-redux";
import DashboardForm from "./DashboardForm";
import ToDoList from "./ToDoList";
import { useFetchProfilesQuery, useUpdateAcosMutation, useUpdateAvgPriceMutation, useUpdateMaxBidMutation } from "../../store";
import { updateAcosState, updateAvgPriceState, updateMaxBidState } from "../../store/slices/accountsSlice";
import SelectAccountDiv from "../SelectAccountDiv"

function DashboardPage() {
    const dispatch = useDispatch();
    const accountId = useSelector(state => state.accounts.accountId);
    const targetAcos = useSelector(state => state.accounts.targetAcos);
    const avgPrice = useSelector(state => state.accounts.avgPrice);
    const maxBid = useSelector(state => state.accounts.maxBid);
    const [updateAcos] = useUpdateAcosMutation();
    const [updateAvgPrice] = useUpdateAvgPriceMutation();
    const [updateMaxBid] = useUpdateMaxBidMutation();
    //BUNLARI DashboardForma gönder ve güncelleme yapılınca state de güncellensin ki güncel verileri görebilelim
    const updateAcosState1 = (account) => { dispatch(updateAcosState(account)) };
    const updateAvgPriceState1 = (account) => { dispatch(updateAvgPriceState(account)) };
    const updateMaxBidState1 = (account) => { dispatch(updateMaxBidState(account)) };
    const { data, error, isFetching } = useFetchProfilesQuery();

    let formAcos, formPrice, formBid;
    let profileName, merchantId;

    if (isFetching) {
        profileName = "Loading...";
        formAcos = <div>Loading...</div>
    } else if (error) {
        profileName = "Error!";
        formAcos = <div>Error! Please contact Patara Consulting.</div>
    } else if (data && accountId) {
        const currentProfile = data.find(profile => {
            return profile.accountId === accountId;
        });
        profileName = currentProfile.accountName
        merchantId = currentProfile.sellerId
        formAcos = <DashboardForm accountId={accountId} updateState={updateAcosState1} updateValue={updateAcos} target={targetAcos} title={"Target ACOS"} char={"%"}></DashboardForm>
        formPrice = <DashboardForm accountId={accountId} updateState={updateAvgPriceState1} updateValue={updateAvgPrice} target={avgPrice} title={"Average Price"} char={"$"}></DashboardForm>
        formBid = <DashboardForm accountId={accountId} updateState={updateMaxBidState1} updateValue={updateMaxBid} target={maxBid} title={"Max Bid"} char={"$"}></DashboardForm>
    }

    return (
        <div>
            {accountId
                ? <div className="flex flex-col m-2">
                    <div className="flex justify-center px-5 py-2 rounded-lg text-base shadow border">
                        {profileName}
                        <div className="text-xxs flex text-sky-700 items-center mx-2">Account ID: {accountId}</div>
                        <div className="text-xxs flex text-sky-700 items-center mx-2">Merchant ID: {merchantId}</div>
                    </div>
                    <div className="flex flex-row justify-center px-5 py-2">
                        {formAcos}
                        {formPrice}
                        {formBid}
                    </div>
                    <ToDoList accountId={accountId}></ToDoList>

                </div>
                : <SelectAccountDiv></SelectAccountDiv>
            }

        </div>
    );
}

export default DashboardPage;