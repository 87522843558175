//import axios from "axios";
import Header from "./components/Header"
import Header2 from "./components/Header2"
import Header2Mobile from "./components/Header2Mobile"
import Sidebar from "./components/Sidebar";
import Footer2 from "./components/Footer2"
import Footer2Mobile from "./components/Footer2Mobile"
import Route from './components/Route';
import DashboardPage2 from './components/Dashboard/DashboardPage2';
import OptimizationPage from "./components/Targetings/OptimizationPage";
import OptimizationPage2 from "./components/Targetings/OptimizationPage2";
import OptimizationPageSb from "./components/Targetings/OptimizationPageSb";
import OptimizationPageSd from "./components/Targetings/OptimizationPageSd";
import CampaignPage from "./components/Campaigns/CampaignPage";
import CampaignPageSb from "./components/Campaigns/CampaignPageSb";
import CampaignPageSd from "./components/Campaigns/CampaignPageSd";
import ReportsPage from "./components/Reports/ReportsPage";
import InventoryPage from "./components/Inventory/InventoryPage";
import SearchTermPage from "./components/SearchTerms/SearchTermPage";
import OverviewPage from "./components/Overview/OverviewPage";
import OverviewPageSb from "./components/Overview/OverviewPageSb";
import PolicyPage from "./components/Home/PrivacyPolicyPage";
import PolicyPageMobile from "./components/Home/PrivacyPolicyPageMobile";
import HomePage from "./components/Home/HomePage";
import HomePageMobile from "./components/Home/HomePageMobile";
import ServicesPage from "./components/Home/ServicesPage";
import ServicesPageMobile from "./components/Home/ServicesPageMobile";
import ContactUsPage from "./components/Home/ContactUsPage";
import PerformancePage from "./components/Performance/PerformancePage";
import SalesTrafficPage from "./components/SalesTraffic/SalesTrafficPage";
import PatariansPage from "./components/Patarians/Patarians";
import ListingPage from "./components/Listings/ListingsPage";
import isMobileCheck from "./components/useCheckMobile"
import WelcomePage from "./components/Admin/WelcomePage";
import ParentsPage from "./components/Parents/ParentsPage";
import GamesHomePage from "./components/Games/GamesHomePage";

function App() {
    const isMobile = isMobileCheck()
    return (
        <div>
            {!isMobile ?
                <div className="text-sm selection:text-white selection:bg-yellow-500" >
                    <Route path='/' >
                        <Header2></Header2>
                        <HomePage></HomePage>
                        <Footer2></Footer2>
                    </Route>
                    <Route path='/services' >
                        <Header2></Header2>
                        <ServicesPage></ServicesPage>
                        <Footer2></Footer2>
                    </Route>
                    <Route path='/contactus' >
                        <Header2></Header2>
                        <ContactUsPage></ContactUsPage>
                        <Footer2></Footer2>
                    </Route>
                    <Route path='/privacypolicy'>
                        <div className="flex flex-col">
                            <Header2></Header2>
                            <PolicyPage></PolicyPage>
                            <Footer2></Footer2>
                        </div>
                    </Route>

                    <Route path='/targeting' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <OptimizationPage></OptimizationPage>
                    </Route>

                    <Route path='/targeting2' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <OptimizationPage2></OptimizationPage2>
                    </Route>

                    <Route path='/targetingsb' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <OptimizationPageSb></OptimizationPageSb>
                    </Route>

                    <Route path='/targetingsd' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <OptimizationPageSd></OptimizationPageSd>
                    </Route>

                    <Route path='/reports' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <ReportsPage></ReportsPage>
                    </Route>

                    <Route path='/dashboard' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <DashboardPage2></DashboardPage2>
                    </Route>

                    <Route path='/inventory' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <InventoryPage></InventoryPage>
                    </Route>

                    <Route path='/campaigns' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <CampaignPage></CampaignPage>
                    </Route>

                    <Route path='/campaignssb' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <CampaignPageSb></CampaignPageSb>
                    </Route>

                    <Route path='/campaignssd' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <CampaignPageSd></CampaignPageSd>
                    </Route>

                    <Route path='/searchterms' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <SearchTermPage></SearchTermPage>
                    </Route>

                    <Route path='/overview' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <OverviewPage></OverviewPage>
                    </Route>

                    <Route path='/overviewsb' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <OverviewPageSb></OverviewPageSb>
                    </Route>

                    <Route path='/admin' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <WelcomePage></WelcomePage>
                    </Route>

                    <Route path='/performance' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <PerformancePage></PerformancePage>
                    </Route>

                    <Route path='/salestraffic' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <SalesTrafficPage></SalesTrafficPage>
                    </Route>

                    <Route path='/patarians' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <PatariansPage></PatariansPage>
                    </Route>

                    <Route path='/listings' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <ListingPage></ListingPage>
                    </Route>

                    <Route path='/parents' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <ParentsPage></ParentsPage>
                    </Route>
                    <Route path='/games' >
                        <Header></Header>
                        <Sidebar></Sidebar>
                        <GamesHomePage></GamesHomePage>
                    </Route>
                </div>
                :
                <div className="text-sm" >
                    <Route path='/' >
                        <Header2Mobile></Header2Mobile>
                        <HomePageMobile></HomePageMobile>
                        <Footer2Mobile></Footer2Mobile>
                    </Route>
                    <Route path='/services' >
                        <Header2Mobile></Header2Mobile>
                        <ServicesPageMobile></ServicesPageMobile>
                        <Footer2Mobile></Footer2Mobile>
                    </Route>
                    <Route path='/contactus' >
                        <Header2Mobile></Header2Mobile>
                        <ContactUsPage></ContactUsPage>
                        <Footer2Mobile></Footer2Mobile>
                    </Route>
                    <Route path='/privacypolicy'>
                        <div className="flex flex-col">
                            <Header2Mobile></Header2Mobile>
                            <PolicyPageMobile></PolicyPageMobile>
                            <Footer2Mobile></Footer2Mobile>
                        </div>
                    </Route>
                </div>
            }
        </div>
    );
}

export default App;